<template>
  <span>
    <slot name="activator" v-bind:openModal="openModal" />
    <v-dialog v-model="dialog" max-width="290">
      <v-card>
        <v-card-title class="text-h5">{{ title }}</v-card-title>
        <v-card-text v-html="content" />
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" :loading="loading" text @click="dialog = false">Nie</v-btn>
          <v-btn
            color="red darken-1"
            :loading="loading"
            text
            @click="
              $emit('confirm');
              dialog = false;
            "
          >
            Tak
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </span>
</template>
<script>
export default {
  name: 'ConfirmModal',
  data() {
    return { dialog: false };
  },
  props: {
    title: {
      type: String,
      required: false,
    },
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
    content: {
      type: String,
      required: false,
    },
  },
  methods: {
    openModal() {
      this.dialog = true;
    },
  },
};
</script>
