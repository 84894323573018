<template>
  <span v-if="file">
    <vue-easy-lightbox
      escDisabled
      moveDisabled
      :visible="visible"
      :imgs="images"
      :index="index"
      @hide="handleHide"
    ></vue-easy-lightbox>
    <img :style="`max-height: ${maxHeight}; width: auto;`" :src="file.url" @click="show()" />
  </span>
</template>
<script>
export default {
  name: 'ShowFile',
  data() {
    return {
      images: [this.file?.url],
      visible: false,
      index: 0,
    };
  },
  methods: {
    handleHide() {
      this.visible = false;
    },
    show() {
      this.visible = true;
    },
    showSingle() {
      this.images = [this.file.url];
      this.show();
    },
  },
  props: {
    maxHeight: {
      type: String,
      required: false,
      default: null,
    },
    file: {
      type: Object,
      required: false,
    },
  },
};
</script>
<style scoped>
img {
  width: 100%;
}
</style>
